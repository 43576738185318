<template>
  <div>
      <barra-relatorios></barra-relatorios>
  </div>
</template>

<script>
import BarraRelatorios from '../../components/common/BarraRelatorios.vue';
export default {
    components:{
        BarraRelatorios
    }
}
</script>

<style>

</style>